<template>
  <router-view/>
</template>

<style lang="scss">
body, html, #app {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  background: #000;
}

* {
  box-sizing: border-box !important;
}
</style>
