import { createApp } from 'vue'
import { createHead } from '@unhead/vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Import normalize
import './assets/normalize.css'

const app = createApp(App).use(store).use(router);

const head = createHead();
app.use(head);

app.mount('#app');