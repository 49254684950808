<template>
  <div class="home" :class="{ 'loading': isLoading }">

    <!-- Loading -->
    <loader v-if="isLoading"></loader>
    <!-- / Loading -->

    <!-- Normal Landing -->
    <div v-if="!isLoading && shouldShowNormalLandingPage" class="landing-page" v-html="landingPage.landingPage.html">
    </div>
    <!-- / Normal Landing -->

    <!-- Post Credentials Captured Page -->
    <div v-if="!isLoading && shouldShowPostCredentialsCapturedPage" class="landing-page"
      v-html="landingPage.postCaptureCredentialsLandingPage.html"></div>
    <!-- / Post Credentials Captured Page -->

    <!-- What Did Wrong -->
    <what-did-wrong v-if="!isLoading && shouldShowWhatDidWrongLandingPage" :api-base="apiBase"
      :landing-page="landingPage" :email-guid="guid"></what-did-wrong>
    <!-- / What Did Wrong -->

  </div>
</template>

<script>
import Loader from '@/components/Loader.vue';
import axios from 'axios';
import WhatDidWrong from '@/components/WhatDidWrong.vue';
export default {
  name: 'HomeView',
  head() {
    return {
      title: this.landingPageTitle
    }
  },
  data() {
    return {
      isLoading: false,
      landingPage: null,

      credentialsCaptured: false
    }
  },
  components: { Loader, WhatDidWrong },
  created() {
    if (this.guid) {
      this.fetch();
    }
  },
  methods: {
    fetch() {
      // If guid is 6 characters long, it's an sms, otherwise it's an email
      if (this.guid.length === 6) {
        this.fetchSms();
      } else {
        this.fetchEmail();
      }
    },

    fetchEmail() {
      this.isLoading = true;
      axios.get(this.apiBase + '/email-landing-page/' + this.guid)
        .then(r => {
          this.landingPage = r.data;
          this.isLoading = false;

          this.$nextTick(() => {
            this.checkForPhishrRedirect();
            this.attachFormSubmitListener();
            this.updatePageTitle();
          });
        })
        .catch(error => {
          console.error(error);
          // this.isLoading = false;
        });
    },

    fetchSms() {
      this.isLoading = true;
      axios.get(this.apiBase + '/sms-landing-page/' + this.guid)
        .then(r => {
          this.landingPage = r.data;
          this.isLoading = false;
        })
        .catch(error => {
          console.error(error);
          // this.isLoading = false;
        });
    },

    updatePageTitle() {
      if (this.landingPageTitle) {
        document.title = this.landingPageTitle;
      }
    },

    checkForPhishrRedirect() {
      if (
        this.landingPage && this.landingPage.landingPage && this.landingPage.landingPage.html
        && this.landingPage.landingPage.html.includes('phishr-redirect')
      ) {
        const match = this.landingPage.landingPage.html.match(/phishr-redirect="([^"]+)"/)[1];
        if (match) {
          window.location.href = match;
        }
      }
    },

    attachFormSubmitListener() {
      // For every form in the landing page, attach a submit listener that doesn't submit the form
      const forms = document.querySelectorAll('.landing-page form');
      forms.forEach(form => {
        form.addEventListener('submit', e => {
          e.preventDefault();

          // Validate all fields in form are provided that are text or password
          const inputs = form.querySelectorAll('input [type="text"], input[type="password"]');
          let allFieldsProvided = true;
          inputs.forEach(input => {
            if (!input.value) {
              allFieldsProvided = false;
            }
          });
          if (!allFieldsProvided) {
            alert('Please provide all required fields');
            return;
          }

          // this.credentialsCaptured = true;
          this.handleCredentialsCaptured();
        });
      });
    },

    handleCredentialsCaptured() {
      if (this.landingPage.landingPage.postCredentialCapture.behaviour == 'default') {
        this.credentialsCaptured = true;
      }
      axios.post(this.apiBase + '/email-landing-page/' + this.guid + '/credentials-submitted').then(() => {
        if (this.landingPage.landingPage.postCredentialCapture.behaviour == 'redirect') {
          window.location.href = this.landingPage.landingPage.postCredentialCapture.redirectUrl;
        }
      }).catch(e => {
        console.log(e);
      });
    }
  },
  computed: {
    guid() {
      return this.$route.params.guid;
    },

    environment() {
      // If query string has lt, it's load-testing
      if (this.$route.query.lt) {
        return 'load-testing';
      }

      // If query string has t, is staging, otherwise is production
      return this.$route.query.t ? 'staging' : 'production';
    },

    apiBase() {
      // If environment is load-testing
      if (this.environment === 'load-testing') {
        return 'https://load-testing-api.phishr.com/api';
      }

      return this.environment === 'staging' ? 'https://api-testing.phishr.com/api' : 'https://api.phishr.com/api';
    },

    landingPageTitle() {
      return (this.landingPage && this.landingPage.landingPage && this.landingPage.landingPage.pageTitle ? this.landingPage.landingPage.pageTitle : "Loading...");
    },

    interactiveTrainingEnabled() {
      return this.landingPage && this.landingPage.interactiveTrainingEnabled;
    },

    captureCredentials() {
      return this.landingPage && this.landingPage.captureCredentials;
    },

    hasWhatDidWrong() {
      if (this.landingPage && this.landingPage.email && this.landingPage.email.hasWhatDidWrong) {
        return true;
      }
      if (this.landingPage && this.landingPage.sms && this.landingPage.sms.hasWhatDidWrong) {
        return true;
      }
      return false;
    },

    hasWhatDidWrongAndWhatDidWrongEnabled() {
      return this.hasWhatDidWrong && this.interactiveTrainingEnabled;
    },

    shouldShowNormalLandingPage() {
      // return !this.isLoading && this.landingPage && this.landingPage.landingPage && this.landingPage.landingPage.html && !(this.hasWhatDidWrong && this.landingPage.interactiveTrainingEnabled);

      // We want to show the landing page if
      // 1. Capture credentials is enabled and credentials have not been captured
      // 2. Capture credentials is disabled and there's no what did wrong

      if (this.captureCredentials) {
        return this.landingPage && this.landingPage.landingPage && this.landingPage.landingPage.html && !this.credentialsCaptured;
      } else {
        return this.landingPage && this.landingPage.landingPage && this.landingPage.landingPage.html && !this.hasWhatDidWrongAndWhatDidWrongEnabled;
      }
    },

    shouldShowPostCredentialsCapturedPage() {
      // Show post credentials captured page if
      // 1. Credentials captured and
      // 2. Interactive training is not enabled

      return this.credentialsCaptured && !this.hasWhatDidWrongAndWhatDidWrongEnabled;
    },

    shouldShowWhatDidWrongLandingPage() {
      // Show what did wrong landing page if
      // 1. Credentials captured and interactive training is enabled
      // 2. Capture credentials is disabled and there's what did wrong

      if (this.captureCredentials) {
        return this.credentialsCaptured && this.hasWhatDidWrongAndWhatDidWrongEnabled;
      } else {
        return this.hasWhatDidWrongAndWhatDidWrongEnabled;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  min-height: 100%;
  min-width: 100%;
  background: #fff;

  &.loading {
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .landing-page {
    height: 100vh;
    width: 100%;
  }
}
</style>